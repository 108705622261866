<template>
  <div id="app">
    <main-menu></main-menu>
    <div class="container content" role="main">
      <outdated-alert></outdated-alert>
      <router-view></router-view>
      <bottom-right-block/>
    </div>
    <footer class="container-fluid">
      <div class="partners">
        <h3>Партнёр олимпиады</h3>
        <div class="container">
          <a href="https://proctoredu.ru/" target="_blank">
            <img src="./assets/images/proctor-edu.svg" alt="ProctorEdu">
          </a>
        </div>
      </div>
      <div class="container links">
        <div class="copyright">
          &copy; 2016-{{new Date().getFullYear()}} {{ $t('layout.copyright') }}
        </div>
        <div class="link">
          <a href="mailto:olymp_support@itmo.ru" target="_blank">
            olymp_support@itmo.ru
          </a>
        </div>
        <div class="link">
          <router-link :to="`/${lang}/s/privacy`">
            {{ $t('layout.privacy') }}
          </router-link>
        </div>
        <div class="link">
          <router-link :to="`/${lang}/s/contacts`">
            {{ $t('layout.contacts') }}
          </router-link>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>

  import './filters/date';
  import './filters/timer';
  import './filters/duration';
  import 'moment/locale/ru';
  import commonModel from './models/common';
  import MainMenu from './components/ui/MainMenu';
  import OutdatedAlert from './components/ui/OutdatedBrowserAlert';
  import BottomRightBlock from "./components/ui/BottomRightBlock";

  export default {
    components: {
      BottomRightBlock,
      MainMenu,
      OutdatedAlert
    },
    data()
    {
      return {
        lang: commonModel.getCurrentLang()
      }
    },
  }

</script>
<style scoped>
  footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #fff;
    color: #505050;
    font-size: 12px;
    border-top: 2px solid #1946ba;
  }

  footer .container {
    display: flex;
    align-items: center;
    align-content: space-evenly;
    min-height: 72px;
    text-align: center;
  }

  footer .links {
    gap: 16px;
  }

  footer .links div {
    flex-grow: 1;
  }

  footer .container .link {
    font-size: 1.75rem;
    font-weight: 500;
  }

  footer .partners {
    flex-direction: column;
    text-align: center;
  }

  footer .partners .container {
    justify-content: center;
    gap: 24px;
  }

  footer .partners img {
    height: 40px;
  }

  @media (max-width: 768px) {
    footer .container {
      flex-direction: column;
    }

    footer .partners h3 {
      margin-bottom: -8px;
    }
  }
</style>
